import {createRouter, createWebHistory} from 'vue-router'

import IncidentView from './components/IncidentView.vue'

const routes = [
    {path: '/:urlToken', component: IncidentView},
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
})
