<template>
  <div v-if="this.expired">
    <h2>This page has expired</h2>
  </div>
  <div v-else-if="this.errored">
    <h2>This page has experienced an unexpected error</h2>
  </div>
  <div v-else-if="this.incident === null">
    <h2>Looking for alert: {{ $route.params.urlToken }}</h2>
  </div>
  <div v-else>
    <IncidentHeader :incident="this.incident"/>
    <IncidentLocation v-if="this.locationCount > 0" :incident="this.incident"/>
    <IncidentDetails :incident="this.incident"/>
    <SensorOwner v-if="this.hasOwner" :incident="this.incident"/>
    <AnywherePet v-if="this.hasAnywherePet" :incident="this.incident"/>
  </div>
</template>

<script>
import { getDatabase, ref, onValue } from 'firebase/database'
import { get, isObject } from 'lodash'
import IncidentLocation from '@/components/IncidentLocation'
import IncidentHeader from '@/components/IncidentHeader'
import IncidentDetails from '@/components/IncidentDetails'
import SensorOwner from '@/components/SensorOwner'
import AnywherePet from '@/components/AnywherePet'
import { useFavicon, useTitle } from '@vueuse/core'

import * as mp from '@/message_path'
import { BROADCAST, SENSOR_STIMULI, SYSTEM_EVENT } from '@/const'

const DEFAULT_FAV_ICON = 'favicon-32x32.png'
const DEFAULT_TITLE = 'Centurion Anywhere'

const icon = useFavicon()
const title = useTitle(DEFAULT_TITLE)


export default {
  name: 'IncidentView',
  components: {
    IncidentLocation,
    IncidentHeader,
    IncidentDetails,
    SensorOwner,
    AnywherePet,
  },
  data () {
    return {
      incident: null,
      urlToken: null,
      expired: false,
      errored: false,
    }
  },
  computed: {
    classification () {
      return get(this.incident, mp.DICTIONARY_CLASSIFICATION)
    },
    sensorOwner () {
      return get(this.incident, 'SensorOwner')
    },
    hasOwner () {
      return isObject(this.sensorOwner)
    },
    anywhereDevice () {
      return get(this.incident, 'AnywhereDevice')
    },
    hasAnywhereDevice () {
      return isObject(this.anywhereDevice)
    },
    sensorIsPet () {
      return get(this.incident, 'Manufacturer.Model') === 'PET'
    },
    hasAnywherePet () {
      return this.hasAnywhereDevice && this.sensorIsPet
    },
    locations () {
      return get(this.incident, mp.LOCATION_RECORDS, [])
    },
    locationCount () {
      return this.locations.length
    },
  },
  methods: {
    updateFavIcon(){
      icon.value = get(this.incident, mp.TEMPLATE_IMAGE_FILENAME, DEFAULT_FAV_ICON)
    },
    updateTitle(){
      switch (this.classification) {
        case SYSTEM_EVENT:
          title.value = get(this.incident, mp.SYSTEM_EVENT_TEXT_MERGED)
          break

        case BROADCAST:
          title.value = get(this.incident, mp.BROADCAST_TEXT)
          break;

        case SENSOR_STIMULI:
          title.value = get(this.incident, mp.NOTIFICATION_TEXT_MERGED)
          break

        default:
          title.value = DEFAULT_TITLE
      }
    },
    subscribeToNotificationContent (urlToken) {
      this.unsubscribeFromNotificationContent()

      // Create the reference to the data we are interested in
      const contentRef = ref(getDatabase(), `/Pages/${urlToken}/Content`)

      // Create a subscription, which will continue to call a callback with a snapshot of the
      // data as the data changes or calls a cancel callback with an error, until the unsubscribed
      // is called
      this.notificationUnsubscribe = onValue(contentRef, (snapshot) => {
            if (snapshot.exists()) {
              // console.log(snapshot.val())
              this.incident = snapshot.val()
            } else {
              this.incident = null
            }
            this.updateFavIcon()
            this.updateTitle()
          },
          (err) => {
            if (err.code === 'PERMISSION_DENIED') {
              this.expired = true
            } else {
              console.error(err)  // This is where the permission error showed up.
              this.errored = true
            }
          })
    },
    unsubscribeFromNotificationContent () {
      if (this.notificationUnsubscribe) {
        this.notificationUnsubscribe()
        this.notificationUnsubscribe = null
      }
    },
    unsubscribeAll () {
      this.unsubscribeFromNotificationContent()
    },
  },
  mounted () {  // https://vuejs.org/api/options-lifecycle.html#mounted
    const urlToken = this.$route.params.urlToken
    this.subscribeToNotificationContent(urlToken)
  },
  // TODO: Should perhaps change to https://vuejs.org/api/options-lifecycle.html#unmounted, I need to learn more
  beforeUnmount () {  // https://vuejs.org/api/options-lifecycle.html#beforeunmount
    this.unsubscribeAll()
  },
}
</script>

<style scoped>
pre {
  text-align: left;
}

</style>
