<template>
  <MappingTable title="Details" :obj="this.incident" :map="this.map"/>
</template>

<script>
import MappingTable from '@/components/MappingTable'
import { get, curryRight, isString } from 'lodash'

import { BROADCAST, SYSTEM_EVENT, SENSOR_STIMULI } from '@/const'
import * as mp from '@/message_path'

export default {
  components: {
    MappingTable,
  },
  name: 'IncidentHeader',
  props: {
    incident: Object,
  },
  computed: {
    classification () {
      return get(this.incident, mp.DICTIONARY_CLASSIFICATION)
    },
    map () {
      switch (this.classification){
        case BROADCAST:
          return {
            'User Name': curryRight(get)(mp.BROADCAST_USERNAME, ''),
            'Dictionary Classification': curryRight(get)(mp.DICTIONARY_CLASSIFICATION, undefined),
            'Dictionary Revision': curryRight(get)(mp.DICTIONARY_REVISION, undefined),
            'Integration Code': curryRight(get)(mp.BROADCAST_INTEGRATION_CODE, ''),
          }

        case SYSTEM_EVENT:
          return {
            'Category': curryRight(get)(mp.SYSTEM_EVENT_CATEGORY, ''),
            'Name': curryRight(get)(mp.SYSTEM_EVENT_NAME, ''),
            'Serial Number': curryRight(get)(mp.SYSTEM_EVENT_SER_NO, ''),
            'Dictionary Classification': curryRight(get)(mp.DICTIONARY_CLASSIFICATION, undefined),
            'Dictionary Revision': curryRight(get)(mp.DICTIONARY_REVISION, undefined),
          }

        case SENSOR_STIMULI:
          return {
            'Sensor Number': curryRight(get)(mp.SENSOR_NUMBER, ''),
            'Sensor Name': curryRight(get)(mp.SENSOR_NAME, ''),
            'Sensor Description': curryRight(get)(mp.SENSOR_DESCRIPTION, ''),
            'Sensor Type': curryRight(get)(mp.MANUFACTURER_DESCRIPTION, undefined),
            'Sensor Identifier': curryRight(get)(mp.SENSOR_IDENTIFIER, ''),
            'Sensor Model': curryRight(get)(mp.MANUFACTURER_MODEL, undefined),
            'In Service Since': obj => this.toDate(get(obj,mp.SENSOR_IN_SERVICE_AT, undefined)),
            'Zone': curryRight(get)(mp.ZONE_NAME, undefined),
            'Notification Number': curryRight(get)(mp.NOTIFICATION_NUMBER, undefined),
            'Dictionary Classification': curryRight(get)(mp.DICTIONARY_CLASSIFICATION, undefined),
            'Dictionary Revision': curryRight(get)(mp.DICTIONARY_REVISION, undefined),
            'Use Local Speaker': curryRight(get)(mp.TARGET_SPEAKER_USE_LOCAL_SPEAKER, undefined),
          }

        default:
          return {
            'Dictionary Classification': curryRight(get)(mp.DICTIONARY_CLASSIFICATION, undefined),
            'Dictionary Revision': curryRight(get)(mp.DICTIONARY_REVISION, undefined),
          }
      }
    }
  },
  methods: {
    toDate: (str) => {
      return isString(str) ? new Date(str) : undefined
    }
  }
}
</script>
