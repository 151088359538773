<template>
  <MappingTable title="Owner" :obj="this.incident" :map="this.map"/>
</template>

<script>
import MappingTable from '@/components/MappingTable'
import { get, curryRight } from 'lodash'

import * as mp from '@/message_path'

export default {
  components: {
    MappingTable,
  },
  name: 'SensorOwner',
  props: {
    incident: Object,
  },
  data () {
    return {
      map: {
        'Name': curryRight(get)(mp.SENSOR_OWNER_NAME, ''),
        'Description': curryRight(get)(mp.SENSOR_OWNER_DESCRIPTION, ''),
      },
    }
  }
}
</script>
