<template>
  <MappingTable title="Anywhere PET" :obj="this.incident" :map="this.map"/>
</template>

<script>
import MappingTable from '@/components/MappingTable'
import { get, curryRight } from 'lodash'

import * as mp from '@/message_path'

export default {
  components: {
    MappingTable,
  },
  name: 'AnywherePet',
  props: {
    incident: Object,
  },
  data () {
    return {
      map: {
        'Battery': curryRight(get)(mp.ANYWHERE_DEVICE_BLE_BTN_BAT_PERCENT, ''),
        'Revision': curryRight(get)(mp.ANYWHERE_DEVICE_BLE_BTN_REVISION, ''),
        'Firmware': curryRight(get)(mp.ANYWHERE_DEVICE_BLE_BTN_FIRMWARE, ''),
      },
    }
  }
}
</script>
