<template>
  <KeyValueTable :title="this.title" :rows="this.rows"/>
</template>

<script>
import KeyValueTable from '@/components/KeyValueTable'
import { forEach, isUndefined } from 'lodash'

export default {
  components: {
    KeyValueTable
  },
  name: 'MappingTable',
  props: {
    title: String,
    obj: Object,
    map: Object,
  },
  data () {
    return {}
  },
  computed: {
    rows () {
      const arr = []

      forEach(this.map, (fn, key) => {
        const value = fn(this.obj)

        if (!isUndefined(value)){
          arr.push({key,value})
        }
      })

      return arr
    }
  },
  methods: {},
}
</script>
