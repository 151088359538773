import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore/lite';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

const config = {
    "apiKey": process.env.VUE_APP_API_KEY,
    "appId": process.env.VUE_APP_APP_ID,
    "authDomain": process.env.VUE_APP_AUTH_DOMAIN,
    "databaseURL": process.env.VUE_APP_DATABASE_URL,
    "measurementId": process.env.VUE_APP_MEASUREMENT_ID,
    "messagingSenderId": process.env.VUE_APP_MESSAGING_SENDER_ID,
    "projectId": process.env.VUE_APP_PROJECT_ID,
    "storageBucket": process.env.VUE_APP_STORAGE_BUCKET
}

const app = initializeApp(config);
const db = getFirestore(app);

export default {
    config,
    app,
    db
}