const SENSOR_STIMULI = Object.freeze("Sensor Stimuli")
const BROADCAST = Object.freeze("Broadcast")
const SYSTEM_EVENT = Object.freeze("System Event")

const CLASSIFICATIONS = Object.freeze([
  SENSOR_STIMULI,
  BROADCAST,
  SYSTEM_EVENT
])

module.exports = {
  CLASSIFICATIONS,
  SENSOR_STIMULI,
  BROADCAST,
  SYSTEM_EVENT,

}