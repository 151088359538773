import {createApp} from 'vue';

import Firebase from './firebase'
import {router} from './router'
import App from './App.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
app.config.globalProperties.firebase_app = Firebase.app
app.config.globalProperties.firebase_db = Firebase.db

app.use(router)
    .use(VueGoogleMaps, {
        load: {
            key: Firebase.config.apiKey,
        },
    })
    .mount('#app')
