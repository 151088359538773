module.exports = {
  ANYWHERE_DEVICE_APPLICATION_VERSION: Object.freeze('AnywhereDevice.ApplicationVersion'),
  ANYWHERE_DEVICE_BLE_BTN_BAT_PERCENT: Object.freeze('AnywhereDevice.BleBtnBatPercent'),
  ANYWHERE_DEVICE_BLE_BTN_REVISION: Object.freeze('AnywhereDevice.BleBtnRevision'),
  ANYWHERE_DEVICE_BLE_BTN_FIRMWARE: Object.freeze('AnywhereDevice.BleBtnFirmware'),
  ANYWHERE_DEVICE_BLE_BTN_LABEL: Object.freeze('AnywhereDevice.BleBtnLabel'),
  ANYWHERE_DEVICE_BLE_BTN_MAC: Object.freeze('AnywhereDevice.BleBtnMac'),
  ANYWHERE_DEVICE_KIND: Object.freeze('AnywhereDevice.Kind'),
  ANYWHERE_DEVICE_PHONE: Object.freeze('AnywhereDevice.Phone'),

  BROADCAST_AT: Object.freeze('Broadcast.At'),
  BROADCAST_TEXT: Object.freeze('Broadcast.Text'),
  BROADCAST_USERNAME: Object.freeze('Broadcast.UserName'),
  BROADCAST_INTEGRATION_CODE: Object.freeze('Broadcast.IntegrationCode'),

  DICTIONARY_CLASSIFICATION: Object.freeze('Dictionary.Classification'),
  DICTIONARY_AT: Object.freeze('Dictionary.At'),
  DICTIONARY_IS_PRESENTATION: Object.freeze('Dictionary.IsPresentation'),
  DICTIONARY_REVISION: Object.freeze('Dictionary.Revision'),

  EVENT_CAUSE: Object.freeze('Event.Cause'),
  EVENT_AT: Object.freeze('Event.At'),
  EVENT_CLEARED_AT: Object.freeze('Event.ClearedAt'),
  EVENT_IS_CLEARED: Object.freeze('Event.IsCleared'),

  LOCATION_RECORDS: Object.freeze('Location.Records'),

  MANUFACTURER_DESCRIPTION: Object.freeze('Manufacturer.Description'),
  MANUFACTURER_MODEL: Object.freeze('Manufacturer.Model'),
  MANUFACTURER_NAME: Object.freeze('Manufacturer.Name'),

  NOTIFICATION_LEVEL: Object.freeze('Notification.Level'),
  NOTIFICATION_NUMBER: Object.freeze('Notification.Number'),
  NOTIFICATION_SUBJECT_TEMPLATE: Object.freeze('Notification.SubjectTemplate'),
  NOTIFICATION_SUBJECT_MERGED: Object.freeze('Notification.SubjectMerged'),
  NOTIFICATION_TEXT_TEMPLATE: Object.freeze('Notification.TextTemplate'),
  NOTIFICATION_TEXT_MERGED: Object.freeze('Notification.TextMerged'),
  NOTIFICATION_HAS_AUDIO: Object.freeze('Notification.HasAudio'),

  SENSOR_NUMBER: Object.freeze('Sensor.Number'),
  SENSOR_NAME: Object.freeze('Sensor.Name'),
  SENSOR_DESCRIPTION: Object.freeze('Sensor.Description'),
  SENSOR_IDENTIFIER: Object.freeze('Sensor.Identifier'),
  SENSOR_IN_SERVICE_AT: Object.freeze('Sensor.InServiceAt'),
  SENSOR_UUID: Object.freeze('Sensor.Uuid'),

  SENSOR_OWNER_DESCRIPTION: Object.freeze('SensorOwner.Description'),
  SENSOR_OWNER_NAME: Object.freeze('SensorOwner.Name'),
  SENSOR_OWNER_UUID: Object.freeze('SensorOwner.Uuid'),

  SYSTEM_EVENT_NAME: Object.freeze('SystemEvents.Name'),
  SYSTEM_EVENT_AT: Object.freeze('SystemEvents.At'),
  SYSTEM_EVENT_CATEGORY: Object.freeze('SystemEvents.Category'),
  SYSTEM_EVENT_IS_CONDITION: Object.freeze('SystemEvents.IsCondition'),
  SYSTEM_EVENT_IS_BEEPED: Object.freeze('SystemEvents.IsBeeped'),
  SYSTEM_EVENT_IS_AUDIBLE: Object.freeze('SystemEvents.IsAudible'),
  SYSTEM_EVENT_SER_NO: Object.freeze('SystemEvents.SerNo'),
  SYSTEM_EVENT_ITEM_MERGED: Object.freeze('SystemEvents.ItemMerged'),
  SYSTEM_EVENT_TEXT_MERGED: Object.freeze('SystemEvents.TextMerged'),

  TARGET_SPEAKER_USE_LOCAL_SPEAKER: Object.freeze('TargetSpeaker.UseLocalSpeaker'),

  TEMPLATE_IMAGE_FILENAME: Object.freeze('Template.ImageFileName'),
  TEMPLATE_IMAGE_ALTERNATE_TEXT: Object.freeze('Template.ImageAlternateText'),
  TEMPLATE_IMAGE_UUID: Object.freeze('Template.ImageUuid'),

  ZONE_NAME: Object.freeze('Zone.Name'),
  ZONE_LEVEL: Object.freeze('Zone.Level'),
  ZONE_IGNORE_HOLIDAYS: Object.freeze('Zone.IgnoreHolidays'),
  ZONE_ENTRY_DELAY: Object.freeze('Zone.EntryDelay'),
  ZONE_EXIT_DELAY: Object.freeze('Zone.ExitDelay'),
}
