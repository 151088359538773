<template>
  <div class="container">
    <div class="image"><img :src="this.templateImageFileName" :alt="this.templateImageAlternateText"/></div>
    <div class="text"><h2>{{ this.subject }} at {{ this.timestamp }}</h2></div>
  </div>
  <div v-if="this.hasSubHeading" class="sub-heading">
    <div class="text"><h2>{{ this.subHeading }}</h2></div>
  </div>
  <div v-if="this.isCleared" class="cleared">
    <div class="text"><h2>Cleared at {{ this.clearedAt }}</h2></div>
  </div>

</template>

<script>
import { get } from 'lodash'
import { SYSTEM_EVENT, SENSOR_STIMULI, BROADCAST } from '@/const'
import * as mp from '@/message_path'

export default {
  name: 'IncidentHeader',
  props: {
    incident: Object,
  },
  data () {
    return {}
  },
  computed: {
    classification () {
      return get(this.incident, mp.DICTIONARY_CLASSIFICATION)
    },
    subject () {
      let subj = null
      switch (this.classification) {
        case SYSTEM_EVENT:
          subj = get(this.incident, mp.SYSTEM_EVENT_NAME) || this.classification
          break;

        case SENSOR_STIMULI:
          subj = get(this.incident, mp.EVENT_CAUSE) || this.classification
          break

        default:
          subj = this.classification
      }

      return subj
    },
    subHeading () {
      let str = null
      switch (this.classification) {
        case SYSTEM_EVENT:
          str = get(this.incident, mp.SYSTEM_EVENT_TEXT_MERGED)
          break

        case BROADCAST:
          str = get(this.incident, mp.BROADCAST_TEXT)
          break;

        case SENSOR_STIMULI:
          str = get(this.incident, mp.NOTIFICATION_TEXT_MERGED)
          break
      }

      return str
    },
    hasSubHeading () {
      return this.subHeading !== undefined
    },
    timestamp () {
      const dictionaryAt = get(this.incident, mp.DICTIONARY_AT)
      const eventAt = get(this.incident, mp.EVENT_AT)
      return new Date(eventAt || dictionaryAt)
    },
    templateImageFileName () {
      return get(this.incident, mp.TEMPLATE_IMAGE_FILENAME)
    },
    templateImageAlternateText () {
      return get(this.incident, mp.TEMPLATE_IMAGE_ALTERNATE_TEXT)
    },
    templateImageUuid () {
      return get(this.incident, mp.TEMPLATE_IMAGE_UUID)
    },
    clearedAt () {
      const str = get(this.incident, mp.EVENT_CLEARED_AT)
      return str ? new Date(str) : null
    },
    isCleared () {
      return get(this.incident, mp.EVENT_IS_CLEARED, false)
    },
  },
}
</script>

<style scoped>

div.container {
  display: flex;
  align-items: center;
  justify-content: center
}

img {
  /*max-width: 100%*/
}

div.text {
  /*font-size: 20px;*/
  padding-left: 20px;
}

div.sub-heading, div.cleared {
  font-size: 90%;
}

h2 {
  margin: 0;
}

</style>

