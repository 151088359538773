<template>
  <div class="table_container">
    <table>
      <thead>
      <tr>
        <th colspan="2">{{this.title}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in rows" :key="row.key">
        <td>{{ row.key }}</td>
        <td>{{ row.value }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import { get } from 'lodash'

export default {
  name: 'KeyValueTable',
  props: {
    title: String,
    rows: Array
  },
}
</script>

<style scoped>

table, th, td {
  border: 2px solid lightgray;
  border-collapse: collapse;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}

table {
  width: 100%;
}

th {
  background-color: lightgray;
}

td {
  width: 50%;
}

div.table_container {
  margin: 2em;
}

</style>

