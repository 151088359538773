<template>
  <GMapMap
      :center="this.center"
      :zoom="this.zoom"
      map-type-id="roadmap"
      style="width: 500px; height: 300px"
      ref="gMap">
    <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :icon=m.props.icon
        :clickable="true"
        :draggable="false"
        @click="toggleInfoState(index)">
      <GMapInfoWindow
          :options="{maxWidth: 350}"
          :opened="isInfoOpened(index)"
          :closeclick="true"
          @closeclick="setInfoClosed(index)">
        <div>
          <a v-if="m.position && m.position.lat && m.position.lng"
             target="_blank" rel="noopener noreferrer"
             :href="'https://maps.google.com/?q=' + m.position.lat + ',' + m.position.lng">
            {{ m.position ? m.position.lat : '' }},{{ m.position ? m.position.lng : '' }}
          </a> recorded at: {{ m.timestamp }}
        </div>
      </GMapInfoWindow>
    </GMapMarker>
    <GMapPolyline v-for="(line, index) in lines" :key="index"
                  :path="line.paths"
                  :editable="false"
                  :options="{strokeColor: line.color}"
                  ref="polyline"/>
  </GMapMap>
</template>

<script>

import { debounce, get, map, reduce } from 'lodash'
import * as mp from '@/message_path'
import { ref } from 'vue'
import { useElementSize } from '@vueuse/core'

const ZOOM_MAX = 20
const ZOOM_MIN = 5

export default {
  components: {},
  name: 'IncidentLocation',
  props: {
    incident: Object,
  },
  data () {
    return {
      infoWindowOpened: {},
    }
  },
  setup () {
        const gMap = ref(null)
        const { width, height } = useElementSize(gMap)

        return {
          gMap,
          mapWidth: width,
          mapHeight: height
        }
  },
  computed: {
    locations () {
      return get(this.incident, mp.LOCATION_RECORDS, [])
    },
    bounds () {
      return reduce(
          this.locations,
          (accum, loc) => {
            if (!accum) {
              accum = { north: loc.Latitude, south: loc.Latitude, east: loc.Longitude, west: loc.Longitude }
            } else {
              if (loc.Latitude > accum.north)
                accum.north = loc.Latitude

              if (loc.Latitude < accum.south)
                accum.south = loc.Latitude

              if (loc.Longitude < accum.west)
                accum.west = loc.Longitude

              if (loc.Longitude > accum.east)
                accum.east = loc.Longitude
            }

            return accum
          }, null,
      )
    },
    markers () {
      return map(this.locations, (location, index) => {
        const locationCount = this.locations.length
        const icon = (index === locationCount - 1) ? null : this.transientLocationIcon((index + 1) / locationCount)

        return {
          position: {
            lat: Number(location['Latitude']),
            lng: Number(location['Longitude']),
            altitude: Number(location['Altitude']),
          },
          props: {
            icon: icon,
          },
          timestamp: location['DeviceAt'] ? new Date(location['DeviceAt']) : null,
          opened: index === this.locations.length - 1,
        }
      })
    },
    zoom () {
      const WORLD_DIM = { height: 256, width: 256 }

      const latAngle = this.bounds.north - this.bounds.south
      const lngAngle = this.bounds.east - this.bounds.west

      const latZoom = Math.round(Math.log(this.mapHeight * 360 / latAngle / WORLD_DIM.height) / Math.LN2) - 1
      const lngZoom = Math.round(Math.log(this.mapWidth * 360 / lngAngle / WORLD_DIM.width) / Math.LN2) - 1

      const zoom = Math.min(latZoom, lngZoom, ZOOM_MAX)

      return Math.max(ZOOM_MIN, zoom)
    },
    lines () {
      const arr = []

      for (let i = 0; i < this.locations.length - 1; i++) {
        const start = this.locations[i]
        const end = this.locations[i + 1]
        arr.push({
          index: i,
          paths: [
            { lat: Number(start['Latitude']), lng: Number(start['Longitude']), altitude: Number(start['Altitude']) },
            { lat: Number(end['Latitude']), lng: Number(end['Longitude']), altitude: Number(end['Altitude']) },
          ],
          color: this.transientColor(i / this.locations.length),
        })
      }

      return arr
    },
    path () {
      return map(this.locations, (location) => {
        return {
          lat: Number(location['Latitude']),
          lng: Number(location['Longitude']),
          altitude: Number(location['Altitude']),
        }
      })
    },
    center () {
      return {
        lat: (this.bounds.north + this.bounds.south) / 2,
        lng: (this.bounds.east + this.bounds.west) / 2,
      }
    },
  },
  methods: {
    toggleInfoState: debounce( function (index) {
      this.infoWindowOpened[index] = !this.isInfoOpened(index)
    }, 10),
    setInfoClosed (index) {
      this.infoWindowOpened[index] = false
    },
    isInfoOpened (index) {
      return this.infoWindowOpened[index] === true
    },
    transientColor (percent) {
      const initial = { red: 0x54, green: 0x60, blue: 0xF7 }
      const final = { red: 0xF7, green: 0x60, blue: 0x54 }
      const blend = {
        red: Math.round(initial.red * (1 - percent) + final.red * percent),
        green: Math.round(initial.green * (1 - percent) + final.green * percent),
        blue: Math.round(initial.blue * (1 - percent) + final.blue * percent),
      }

      return '#' + blend.red.toString(16).padStart(2, '0')
          + blend.green.toString(16).padStart(2, '0')
          + blend.blue.toString(16).padStart(2, '0')
    },
    transientLocationIcon (percent) {
      // const final = '#F76054'
      // const initial = '#5460F7'

      return {
        path: 'M 0 -5 A 5 5 0 0 1 0 5 A 5 5 0 0 1 0 -5',
        fillColor: this.transientColor(percent),
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1,
        anchor: { x: 0, y: 0 },
      }
    },
  },
}
</script>

<style>

div.vue-map-container {
  margin: 2em;
  height: 500px;
}

</style>
